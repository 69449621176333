<template>
  <div class="content-wrapper">
    <div class="content-body">
      <report-view ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <section class="article-section section-01" style="flex-direction: row;">
          <div class = "table-side " >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">상위 메뉴</div>
              </div>
            </div>
            <div class="section-body menu-height">
              <div class="body-box table-side" style="flex: 0.5">
                <ejs-grid-wrapper  ref="highMenuStatus" v-bind="highMenuCategory" :dataSource="content.highMenuList"
                  @queryCellInfo="onQueryCellInfoHigh"
                  @headerCellInfo= "menuGridHeaderCellInfo"
                  @recordClick="onRowHighMenuSelected" />
              </div>
            </div>
          </div>
          <div class = "table-side" >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">중위 메뉴</div>
              </div>
            </div>
            <div class="section-body menu-height" >
              <div class="body-box table-side" style="flex: 0.6">
                <ejs-grid-wrapper ref="middleMenuStatus" v-bind="middleMenuCategory" :dataSource="content.middleMenuList"
                  @queryCellInfo="onQueryCellInfoMiddle"
                  @headerCellInfo= "menuGridHeaderCellInfo"
                  @recordClick="onRowMiddleMenuSelected" />
              </div>
            </div>
          </div>
          <div class = "table-side" >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">하위 메뉴</div>
              </div>
            </div>
            <div class="section-body menu-height">
              <div class="body-box table-side" style="flex: 1">
                <ejs-grid-wrapper  ref="lowMenuStatus" v-bind="lowMenuCategory" :dataSource="content.lowMenuList"
                  @queryCellInfo="onQueryCellInfoLow"
                  @headerCellInfo= "menuGridHeaderCellInfo"
                  @recordClick="onRowLowMenuSelected" />
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
    <menu-details-popup
        v-if="isMenuDetailsPopupOpen"
        ref="menuDetailsPopup"
        @popupClosed="onCloseMenuDetailsPopup"
        @popupSaved="onSavedMenuDetailsPopup"
    />
  </div>
</template>

<script>
import ReportView from "../../components/common/report/ReportView.vue";
import ejsGridWrapper from "../../components/common/EjsGridWrapper.vue";
import commonMixin from "../../views/layout/mixin/commonMixin";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "../../api/v2/GolfErpAPI";
import MenuDetailsPopup from "@/views/authority-management/popup/MenuDetailsPopup";

export default {
  name: "MenuManagement",
  mixins: [commonMixin, messagePopupDialogMixin],
  props: {
  },
  components: {
    ReportView
    , ejsGridWrapper
    , MenuDetailsPopup
  },
  created() {
  },
  async mounted() {
    await this.refreshAndGetList("high");
  },
  data() {
    return {
      a : true,
      content: {
        highMenuList: [],
        middleMenuList: [],
        lowMenuList: [],
      },

      moveRowData : null,

      move: {
        prevId: null,
        nowId: null,
        nextId: null,
        size: null,
      },

      highMenuId: null,
      middleMenuId: null,
      isMenuDetailsPopupOpen: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isMenuDetailsPopupOpen
      );
    },
    highMenuCategory() {
      const columns = [
        { field: 'menuName', headerText: '메뉴명', textAlign: 'left', width: 60, type: 'string',},
        { field: 'useFlag', headerText: '사용', displayAsCheckBox: true, textAlign: 'center', width: 50, type: 'boolean',},
        { field: 'authMenuFlag', headerText: '메뉴외권한', displayAsCheckBox: true, textAlign: 'center', width: 50, type: 'boolean',},
        { field: 'move', headerText: '이동', textAlign: 'center', width: 70, type: 'string',},
        { field: 'sortNo', headerText: '정렬 순서', visible: false, type: 'number',},
      ];

      return {
        provides: [
          Filter,
          Resize,
          Page,
          ForeignKey
        ],
        allowSorting: false,
        allowFiltering: false,
        isSelectedRowRetain: false,
        isShowProgress: false,
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        noColumnWidth: 20,
        pageSettings: { pageSize: 20 },
        columns: columns,
      };
    },
    middleMenuCategory() {
      const columns = [
        {
          field: 'menuName', headerText: '메뉴명', textAlign: 'left', width: 80, type: 'string',},
        { field: 'useFlag', headerText: '사용', displayAsCheckBox: true, textAlign: 'center', width: 50, type: 'boolean',},
        { field: 'move', headerText: '이동', textAlign: 'center', width: 70, type: 'string',},
        { field: 'delete', headerText: '삭제', visible: true, textAlign: 'center', width: 50, type: 'String',},
        { field: "type", visible: false, type: 'String', },
      ];

      return {
        provides: [
          Filter,
          Resize,
          Page,
          ForeignKey
        ],
        allowSorting: false,
        allowFiltering: false,
        isSelectedRowRetain: false,
        isShowProgress: false,
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        noColumnWidth: 20,
        pageSettings: { pageSize: 20 },
        columns: columns,
      };

    },
    lowMenuCategory() {
      const columns = [
        { field: 'menuName', headerText: '메뉴명', textAlign: 'left', width: 80, type: 'string',},
        { field: 'routerName', headerText: this.a ? '라우터명' : '권한', textAlign: 'left', width: 100, type: 'string', },
        { field: 'useFlag', headerText: '사용', textAlign: 'center', displayAsCheckBox: true, width: 50, type: 'boolean', },
        { field: 'move', headerText: '이동', textAlign: 'center', width: 70, type: 'string', },
        { field: 'delete', headerText: '삭제', width: 50, visible: true, textAlign: 'center', type: 'String',},
        { field: "type", visible: false, type: 'String', },
      ];

      return {
        provides: [
          Filter,
          Resize,
          Page,
          ForeignKey
        ],
        allowSorting: false,
        allowFiltering: false,
        isSelectedRowRetain: false,
        isShowProgress: false,
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        noColumnWidth: 20,
        pageSettings: { pageSize: 20 },
        columns: columns,
      };

    }
  },
  methods: {
    onQueryCellInfoHigh(args){
      this.makeMoveBtn(args, "high");
      this.makeImage(args);
    },
    onQueryCellInfoMiddle(args) {
      this.makeMoveBtn(args, "middle");
      this.makeDelBtn(args, "middle");
      this.makeImage(args);
    },
    onQueryCellInfoLow(args) {
      this.makeMoveBtn(args, "low");
      this.makeDelBtn(args, "low");
      this.makeImage(args);
    },
    makeMoveBtn(args, type){
      if(args.column.field === 'move' && args.data.menuName !== '추가' ) {
        const btn = document.createElement('button');
        btn.className = "up-btn";
        btn.addEventListener('click', () => {
          this.onMoveUpClick(args, type);
        });
        args.cell.append(btn);



        const btn2 = document.createElement('button');
        btn2.className = "dw-btn";
        btn2.addEventListener('click', () => {
          this.onMoveDownClick(args, type);
        });
        args.cell.append(btn2);
      }
    },
    makeDelBtn(args, type){
      if(args.column.field === 'delete'  && args.data.menuName !== '추가'){
        const delBtn = document.createElement('button');
        delBtn.className = "delete-btn";
        delBtn.addEventListener('click', () => {
          this.onDeleteClick(args, type);
        });
        args.cell.append(delBtn);
      }
    },

    makeImage(args){
      const {
        cell,
        column: {field},
      } = args;
      if ([
        'menuName',
      ].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
      if ([
          'useFlag',
          'authMenuFlag',
      ].includes(field)) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },

    menuGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if ([
        'menuName',
      ].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },

    async onRowHighMenuSelected(args) {
      const {
        column,
        rowData,
        rowIndex,
      } = args;
      if (column?.headerText === "NO" || (column?.headerText === "메뉴명" && rowData.menuName !== "추가") ) {
        this.highMenuId = rowData.menuId;

        await this.refreshAndGetList("middle");

        this.content.lowMenuList = null;
      }
      if (column?.headerText === "메뉴명") {
          this.openCurrentPopup(rowData, null);

      }
      if (column?.headerText === "사용") {
        await this.useFlagChange(rowData, this.content.highMenuList[rowIndex]);
      }
    },

    async onRowMiddleMenuSelected(args) {
      const {
        column,
        rowData,
        rowIndex,
      } = args;

      if (column?.headerText === "NO" || (column?.headerText === "메뉴명" && rowData.menuName !== "추가")) {
        this.middleMenuId = rowData.menuId;
        await this.refreshAndGetList("low");
      }
      if (column?.headerText === "메뉴명") {
        this.openCurrentPopup(rowData, this.highMenuId);
      }
      if (column?.headerText === "사용") {
        await this.useFlagChange(rowData, this.content.middleMenuList[rowIndex]);
      }
    },

    async onRowLowMenuSelected(args) {

      const {
        column,
        rowData,
        rowIndex,
      } = args;

      if (column?.headerText === "메뉴명" ) {
        this.openCurrentPopup(rowData, this.middleMenuId);
      }

      if (column?.headerText === "사용") {
        await this.useFlagChange(rowData, this.content.lowMenuList[rowIndex]);
      }
    },
    async onCloseMenuDetailsPopup(){
      this.isMenuDetailsPopupOpen = false;
    },

    async useFlagChange(rowData, data){
      const params = {
        parentId : this.highMenuId,
        menuName : rowData.menuName,
        routerName : (typeof rowData.routerName == "undefined" ? null : rowData.routerName),
        useFlag : (!rowData.useFlag),
        sortNo : rowData.sortNo,
        menuTooltip : rowData.menuTooltip,
        menuIcon : rowData.menuIcon,
        description : rowData.description,
        menuFlag : rowData.menuFlag,
        chldrnExstFlag : rowData.chldrnExstFlag,
      };

      await GolfErpAPI.patchMenu(rowData.menuId, params);
      data.useFlag = !data.useFlag;
    },

    openCurrentPopup(rowData, id){

      this.isMenuDetailsPopupOpen = true;

      if(rowData.menuName === "추가"){
        this.$nextTick( async () => {
          await this.$refs.menuDetailsPopup.showPopup(id , "new", rowData);
        });
      }else{
        this.$nextTick( async () => {
          await this.$refs.menuDetailsPopup.showPopup(rowData.menuId , "old", rowData);
        });
      }
    },

    async onDeleteClick(args, type) {
      if (!args.data.menuId || !(await this.confirm(this.$t('main.popupMessage.confirmDeleteShort')))) {
        return;
      }
      try {
        await GolfErpAPI.deleteMenu(args.data.menuId);
        this.infoToast(this.$t('main.popupMessage.deleted'));
        this.$emit('popupConfirmed');

        await this.refreshAndGetList(type);

      } catch (error) {
        this.errorToast(error);
      }
    },

    async onMoveUpClick(args, type){
      if(args.data._rid !== 1){
          this.move.nowId = args.data.menuId;
        if(type === "high"){
          this.move.prevId = this.content.highMenuList[args.data._rid - 2].menuId;
        }else if(type === "middle"){
          this.move.prevId = this.content.middleMenuList[args.data._rid - 2].menuId;
        }else{
          this.move.prevId = this.content.lowMenuList[args.data._rid - 2].menuId;
        }
        await GolfErpAPI.moveMenu(this.move.nowId, this.move.prevId);

        await this.refreshAndGetList(type);
      }else{
        this.infoToast(this.$t('맨 위 입니다.'));
      }
    },

    async onMoveDownClick(args, type){
      let canMove;
      if(type === "high"){
        canMove = this.content.highMenuList.length;
      }else if(type === "middle"){
        canMove = this.content.middleMenuList.length -1;
      }else{
        canMove = this.content.lowMenuList.length -1;
      }

      if(args.data._rid !== canMove){
        this.move.nowId = args.data.menuId;
        if(type === "high"){
          this.move.nextId = this.content.highMenuList[args.data._rid].menuId;
        }else if(type === "middle"){
          this.move.nextId = this.content.middleMenuList[args.data._rid].menuId;
        }else{
          this.move.nextId = this.content.lowMenuList[args.data._rid].menuId;
        }
        await GolfErpAPI.moveMenu(this.move.nowId, this.move.nextId);

        await this.refreshAndGetList(type);
      }else{
        this.infoToast(this.$t('맨 아래 입니다.'));
      }

    },

    async onSavedMenuDetailsPopup(typeName , parentId) {

      if(typeName === "middle") {
        this.highMenuId = parentId;
      }else{
        this.middleMenuId = parentId;
      }

       await this.refreshAndGetList(typeName);
       this.isMenuDetailsPopupOpen = false;
    },

    async refreshAndGetList(type){
      if(type === "high") {
        const data = await GolfErpAPI.getMenuList();
        this.content.highMenuList = data.highMenuList;
      }else if(type === "middle"){
        const data = await GolfErpAPI.getMenuList(this.highMenuId);
        this.content.middleMenuList = data.middleMenuList;
        this.content.middleMenuList.push({
          menuName: "추가",
          useFlag: null,
          move: null,
          delete: null,
          menuFlag: false,
          chldrnExstFlag: true,
          type:"middle",
        });
      }else {
        const data = await GolfErpAPI.getMenuList(this.highMenuId, this.middleMenuId);
        this.a = (this.highMenuId !== '9999');

        this.content.lowMenuList = data.lowMenuList;
        this.content.lowMenuList.push({
          menuName: "추가",
          useFlag: null,
          move: null,
          delete: null,
          menuFlag: true,
          chldrnExstFlag: false,
          type:"low",
        });
      }
    }
  },

};
</script>

<style scoped>
body .article-section >>> .e-grid .up-btn {
  background:  url("../../assets/images/common/up-arrow.png") center, center;
  width: 20px;
  height: 20px;
  border: 0;
  cursor: pointer;
}
body .article-section >>> .e-grid .dw-btn {
  background: url("../../assets/images/common/dw-arrow.png") center,center;
  width: 20px;
  height: 20px;
  border: 0;
  cursor: pointer;
}
body .article-section >>> .e-grid .delete-btn {
  background:  url("../../assets/images/common/auth-delete.png") center, center;
  width: 20px;
  height: 20px;
  border: 0;
  cursor: pointer;
}

.table-side {
  margin: 10px;
}
.menu-height {
  height: 700px;
}

</style>
