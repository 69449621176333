<template>
  <div>
    <ejs-dialog
      ref="MenuDetailPopup"
      :header="`${menuInfo.menuId ? '상세' : '등록'} 메뉴`"
      width="600"
      :animationSettings="{ effect: 'None' }" :allowDragging="true" :showCloseIcon="true" :isModal="true"
      :cssClass="
        menuInfo.menuId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      :close="closePopup">
      <div class="window menuInfo">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01" >
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">메뉴 등록</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section">
                        <div class="section-body" style="border: none">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field voc-section">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      메뉴명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text id="menuName" ref="menuName"
                                                        v-model="menuInfo.menuName"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field voc-section" v-if="popupType">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      라우터명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text id="routerName" ref="routerName"
                                                        v-model="menuInfo.routerName"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field voc-section">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                        사용
                                      </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input type="checkbox" id="useFlag" ref="useFlag"
                                                       v-model="menuInfo.useFlag"/>
                                                <i/>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field voc-section">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      정렬
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text id ="sortNo" ref ="sortNo"
                                                        v-model ="menuInfo.sortNo"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field voc-section">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      메뉴 툴팁
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text id ="menuTooltip" ref="menuTooltip"
                                                        v-model="menuInfo.menuToolTip"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field voc-section">
                                    <div class="title">
                                      메뉴 아이콘
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text id ="menuIcon" ref="menuIcon"
                                                        v-model="menuInfo.menuIcon"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field voc-section">
                                    <div class="title">
                                      설명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text id ="description" ref="description"
                                                        v-model="menuInfo.description"/>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li>
              <erp-button
                  button-div="SAVE"
                  :disabled="!isChangedMenuInfo"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="delete">
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="closePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import { validateFormRefs } from '@/utils/formUtil';
import InputText from '@/components/common/text/InputText';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'MenuDetailsPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    ErpButton,
  },
  data() {
    return {
      popupType: false,
      oldMenuInfo: null,
      typeName : null,
      menuInfo: {
        menuId: null,
        routerName : null,
        menuName: null,
        menuIcon: null,
        menuToolTip: null,
        description: null,
        useFlag: null,
        sortNo: null,
        chldrnExstFlag: false,
        menuFlag: false,
        parentId: null,
      },
      validateRefList: {
        menuName: { required: true },
        useFlag: { required: true },
      },
      isNew: false,
    };
  },
  mounted() {
  },
  computed: {
    isPopupOpened() {
      return (
        this.isCaddieSelectPopupOpen ||
        this.isFindVisitPopupOpen ||
        this.isMemberSelectPopupOpen
      );
    },
    isChangedMenuInfo() {
      return JSON.stringify(this.menuInfo) !== JSON.stringify(this.oldMenuInfo);
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
  methods: {
    validateFormRefs,
    async showPopup(menuId, how, rowData) {
      this.popupType = (rowData.type === "low" || rowData.menuFlag === true);

      if(how === "new"){
        this.menuInfo.parentId = menuId;
        this.menuInfo.useFlag = true;
        this.isNew = true;
        this.menuInfo.menuFlag = (rowData.type === "low" || rowData.menuFlag === true);

      }else{
        this.menuInfo.menuId = menuId;
        await this.fetch();
      }

      console.log(this.menuInfo.parentId);
    },
    closePopup() {
      this.$emit('popupClosed');
      this.isNew = false;
    },
    async onSaveClick() {
      if (!this.validate) {
        return;
      }
      if (!this.isChangedMenuInfo) {
        this.errorToast(this.$t('main.validationMessage.noChanges'));
        return;
      }
      const params = {
        parentId: this.menuInfo.parentId,
        menuName: this.menuInfo.menuName,
        routerName: this.menuInfo.routerName,
        menuFlag: this.menuInfo.menuFlag,
        useFlag: this.menuInfo.useFlag,
        sortNo: this.menuInfo.sortNo,
        menuToolTip: this.menuInfo.menuToolTip,
        menuIcon: this.menuInfo.menuIcon,
        chldrnExstFlag: this.menuInfo.chldrnExstFlag,
        description: this.menuInfo.description,
      };

      if(params.parentId == null){
        this.typeName = "high";
      }else{
        if(params.menuFlag === true){
          this.typeName = "low";
        }else{
          this.typeName = "middle";
        }
      }

      try {
        if(this.isNew){
          await GolfErpAPI.saveMenu(params);
        }else{
          await GolfErpAPI.patchMenu(this.menuInfo.menuId, params);
        }
        this.infoToast(this.$t('main.popupMessage.saved'));
        this.$emit('popupSaved', this.typeName, params.parentId);

      } catch (error) {
        this.errorToast(error);
      }
    },

    // API
    async fetch() {
      const menuInfo = await GolfErpAPI.getMenu(this.menuInfo.menuId);
      this.oldMenuInfo = JSON.parse(JSON.stringify(menuInfo));
      this.menuInfo = menuInfo;

    },
  },
};
</script>

<style scoped>
.voc-section {
  width: 100% !important;
}
</style>
